<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-07 14:07:14
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-10 08:41:42
 * @Description: 仓库管理
 * @FilePath: \src\views\App\Storage\Warehouse\WarehouseManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenNewWarehouseModel()"
        >添加新仓库</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="small"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 仓库名称 -->
      <el-table-column
        label="仓库名称"
        prop="name"
        align="center"
        width="160px"
      ></el-table-column>

      <!-- 仓库简称 -->
      <el-table-column
        label="仓库简称"
        prop="abbrev_name"
        align="center"
        width="160px"
      ></el-table-column>

      <!-- 最大容量 -->
      <el-table-column
        label="最大容量"
        prop="max_volume"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 当前状态 -->
      <el-table-column label="当前状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 仓库地址 -->
      <el-table-column
        label="仓库地址"
        prop="address"
        width="350px"
      ></el-table-column>

      <!-- 描述 -->
      <el-table-column label="描述">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 添加时间 -->
      <el-table-column
        label="创建时间"
        prop="add_time"
        align="center"
        width="140px"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateWarehouseModel(s.row.kwh_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelWarehouse(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-warehouse ref="nu-warehouse"></nu-warehouse>
  </div>
</template>

<script>
import NuWarehouse from "./NUWarehouse";
import { getWarehouseTable, delWarehouse } from "../api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "WarehouseManage",
  components: {
    NuWarehouse,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  watch: {
    //监听组织ID变动刷新表格
    "p.kor_id": {
      handler() {
        this.p.pageNo = 1;
        this._Load();
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getWarehouseTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 130001) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开仓库创建组件
    _OpenNewWarehouseModel() {
      this.$refs["nu-warehouse"].open(0, this.p.kor_id);
    },

    //打开仓库修改组件
    _OpenUpdateWarehouseModel(id) {
      this.$refs["nu-warehouse"].open(id);
    },

    //删除
    _DelWarehouse(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除仓库【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将同步删除仓库下的所有货物记录并且不可撤销!</b>',
        () => {
          delWarehouse({ kwh_id: data.kwh_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>

<style scoped></style>
